import React, { useState, useEffect } from "react"
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Autocomplete from 'react-autocomplete'
import searchFilter from '../../utils/searchFilter'

const resources = {
  en: 'Search for a topic or question here',
  es: 'Busque un tema específico o pregunta aquí'
}

const SearchBox = ({ isAmp, lang }) => {
  // Amp Version
  if (isAmp) return (
    <>
      <Helmet>
        <script async custom-element="amp-autocomplete" src="https://cdn.ampproject.org/v0/amp-autocomplete-0.1.js" />,
        <script async custom-template="amp-mustache" src="https://cdn.ampproject.org/v0/amp-mustache-0.2.js" />,
      </Helmet>

      <div className="search">
        <h3>{resources[lang]}</h3>

        <amp-autocomplete filter="substring" filter-value="label" src={`/search-data-${lang}.json`} on="select:AMP.navigateTo(url=event.value)">
          <input type="search" name="label"/>
          <template type="amp-mustache" id="amp-template-custom">
            <div data-value="{{url}}">
              {"{{label}}"}
            </div>
          </template>
        </amp-autocomplete>
      </div>
    </>
  )

  // HTML Version

  // Client-side Runtime Data Fetching
  const [allItems, setAllItems] = useState([])
  const [items, setItems] = useState([])
  useEffect(() => {
    fetch(`/search-data-${lang}.json`, {
      mode: 'no-cors'
    })
      .then(response => response.json()) // parse JSON from request
      .then(responseData => {
        setAllItems(responseData.items.map((item) => ({
          ...item
        })))
      })
  }, [])

  const [value, setValue] = useState('')

  const handleChange = (event) => {
    const searchValue = event.target.value || ''
    setValue(searchValue)
    // Filter all items into items
    if (searchValue.length > 0) setItems(searchFilter(searchValue, allItems))
  }
  const handleSelect = (selectedValue) => {
    setValue(selectedValue)
    // Find the matching selection
    const selected = allItems.find((item) => item.label === selectedValue)
    if (selected) {
      // Redirect to the url
      window.location = selected.url
    }
  }

  return (
    <div className="search">
      <h3>{resources[lang]}</h3>

      <Autocomplete
        getItemValue={(item) => item.label}
        items={items}
        renderItem={(item, isHighlighted) =>
          <div style={{ padding: '0.5rem 1rem', background: isHighlighted ? 'lightgray' : 'white' }}>
            {item.label}
          </div>
        }
        value={value}
        onChange={(e) => handleChange(e)}
        onSelect={(e) => handleSelect(e)}
      />
    </div>
  )
}

SearchBox.defaultProps = {
  lang: 'en'
}

SearchBox.propTypes = {
  isAmp: PropTypes.bool,
  lang: PropTypes.string
}

export default SearchBox
