import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { Navigation, Header } from '.'

// Styles
// import '../../styles/app.css'

const styles = `
body {
    margin: 0;
}
body, a {
  font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
  color: #3e3e3c;
}
article a {
  color: #00A19B;
  font-weight: 600;
}
h1 {
  font-size: 1.75rem;
}
section {
  margin: 80px 20px 40px 20px;
}
@media (min-width: 600px) {
  section {
    margin: 80px auto 40px auto;
    padding: 0 20px;
    max-width: 800px;
  }
}
.partner-logo {
  max-height: 60px;
  max-width: 300px;
  margin: 0 auto;
}
@media (min-width: 600px) {
  .partner-logo {
    max-width: 600px;
  }
}
footer {
  background-color: #3e3e3c;
  color: #fff;
  padding: 20px;
  font-size: 11px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
footer a {
  color: #fff;
}
footer .embedThis {
  display: none;
  text-align: right;
  padding-right: 8px;
  border-right: 1px solid #fff;
  margin-right: 8px;
}
footer .langVersion {
  padding-left: 8px;
  margin-left: 8px;
  border-left: 1px solid #fff;
}
footer .poweredBy {
  flex-basis: 100%;
  text-align: center;
}
@media (min-width: 600px) {
  footer {
    flex-flow: row nowrap;
  }
  footer .poweredBy {
    flex-basis: auto;
    text-align: left;
  }
  footer .langVersion {
    flex-grow: 1;
  }
  footer .embedThis {
    flex-grow: 1;
    display: none;
  }
}
header {
  position: fixed;
  background: #fff;
  top: 0;
  width: 100%;
  padding: 10px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0 5px 5px #ccc;
  margin-bottom: 5px;
  z-index: 1;
}
header a {
  text-decoration: none;
}
header .logo {
  display: none;
}
header .label {
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
  font-size: 1.0rem;
  line-height: 45px;
}
header .langLink {
  padding-right: 10px;
  font-size: 1rem;
  line-height: 45px;
}
@media (min-width: 600px) {
  header {
    height: 45px;
  }
  header .logo {
    display: block;
    position: absolute;
    width: 100px
  }
  header .label {
    font-size: 1.75rem;
    line-height: 45px;
  }
}
.byline, .furtherReading {
  color: #979797;
  font-size: 12px;
}
.pub-dates span {
  display: block;
  white-space: nowrap;
  color: #979797;
  font-size: 12px;
}
.pub-dates span.separator {
  display: none;
}
@media (min-width: 600px) {
  .pub-dates span, .pub-dates span.separator {
    display: inline;
  }
}
.updateCallout {
  border-top: 1px solid #979797;
  padding: 8px 0;
}
.updateCallout a {
  display: inline-block;
  margin: 0 4px;
  padding: 4px;
  border: 1px solid #979797;
  border-radius: 4px;
  text-decoration: none;
}
.updateCallout a:hover {
  background: #979797;
}
.related {
  border-top: 3px solid #00A19B;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 25px;
}
.related h2 {
  text-transform: uppercase;
  font-size: 1rem;
}
.related h2 a {
  text-decoration: none;
}
.related ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.related li {
  margin-bottom: 10px;
}
.related svg {
  vertical-align: bottom;
}
.search {
  margin: 0 auto;
  width: 100%;
}
.search h3 {
  text-align: center;
}
.search input {
  width: 330px;
}
@media (min-width: 600px) {
  .search, .search input {
    width: 600px;
  }
}
.hamburger, .close-sidebar {
  padding-right: 20px;
  font-size: 1.25rem;
  line-height: 45px;
}
#menubar {
  background-color: #fff;
}
.sidebar {
  min-width: 200px;
  padding: 10px;
  margin: 0;
}
.sidebar > li {
  list-style: none;
  margin-bottom:10px;
}
.sidebar a {
  text-decoration: none;
}
.close-sidebar {
  text-align: right;
  padding: 5px 15px 5px 0;
}
.noamp #menubar {
  display: none;
  position: fixed;
  background-color: #fff;
  top: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  min-width: 45px;
  max-width: 80vw;
}
.noamp #menubar.open {
  display: block;
  z-index: 1000;
}

.noamp #sidebarmask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}
.noamp #sidebarmask.open {
  display: block;
  background-color: rgba(0,0,0,0.5);
}

.noamp .search input {
  font-size: 1rem;
  line-height: 1.5rem;
}

`

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isAmp, isHome, lang='en' }) => {
  const site = data.allGhostSettings.edges[0].node
  const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
  const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

  const FooterLang = ({ lang }) => {
    return lang === 'es' ? (
      <a href="/">English</a>
    ) : (
      <a href="/es/">Español</a>
    )
  }

  return (
    <>
      <Helmet>
        {isAmp ? (
          <html lang={lang} amp="" />
        ) : (
          <html lang={lang} className="noamp" />
        )}

        <style amp-custom="true" type="text/css">{styles}</style>
      </Helmet>

      <Header isAmp={isAmp} lang={lang} />
      <Navigation isAmp={isAmp} lang={lang} navClass="site-nav-item" />

      <section>
        {/* All the main content gets inserted here, index.js, post.js */}
        {children}
      </section>

      <footer>
        <div className="copyright">
          © 2020 Resolve Philly
        </div>
        <div className="langVersion">
          <FooterLang lang={lang} />
        </div>
        <div className="embedThis">
          <a href="/embed/">Embed this content</a>
        </div>
        <div className="poweredBy">
          Powered by <a href="https://distributedmedialab.com/" target="_blank">Distributed Media Lab</a>
        </div>
      </footer>

    </>
  )
}

DefaultLayout.defaultProps = {
  isAmp: false,
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isAmp: PropTypes.bool,
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
      }
    `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
)

export default DefaultLayoutSettingsQuery
