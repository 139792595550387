import React from 'react'
import PropTypes from 'prop-types'

const RelatedListItem = ({ post }) => {
  return post.slug ? (
    <li key={post.slug}>
      <a href={`/${post.slug}/`}>{post.title}</a>
    </li>
  ) : (
    <li key={post.url} className="external">
      <a target="_blank" href={post.url}>
        {post.title}
        <svg width="24px" height="24px" viewBox="0 0 24 24">
          <g id="external_link" class="icon_svg-stroke" stroke="#666" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <polyline points="17 13.5 17 19.5 5 19.5 5 7.5 11 7.5"></polyline>
            <path d="M14,4.5 L20,4.5 L20,10.5 M20,4.5 L11,13.5"></path>
          </g>
        </svg>
      </a>
    </li>
  )
}

RelatedListItem.propTypes = {
  post: PropTypes.shape({}).isRequired
}

export default RelatedListItem
