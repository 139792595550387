// Copyright 2018, Distributed Media Lab, Inc.

import { match } from "minimatch"

export default function (query, items) {
  const loweredQuery = query.toLowerCase().trim()
  const queryParts = loweredQuery.split(' ')

  const fullMatches = items.filter((item) => item.filter.includes(loweredQuery))
  let partMatches = []
  if (queryParts.length > 1) {
    partMatches = items.filter((item) => {
      let matched = false
      queryParts.forEach((queryPart) => {
        if (item.parts.includes(queryPart)) matched = true
      })
      // console.log(item.label, query, matched)
      return matched
    })
  }
  return [...new Set([...fullMatches, ...partMatches])].slice(0, 9)
}
