import React from 'react'
import PropTypes from 'prop-types'
import { navigation } from '../../utils/siteConfig'

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const Navigation = ({ isAmp, lang }) => {
  // Amp Version
  if (isAmp) return (
    <amp-sidebar id="menubar" layout="nodisplay" side="right">
      <div role="button" aria-label="close sidebar" on="tap:menubar.toggle" tabIndex="0" className="close-sidebar">✕</div>
      <ul className="sidebar">
        {navigation[lang].map((navItem, i) => (
          <li><a href={navItem.url}>{navItem.label}</a></li>
        ))}
      </ul>
    </amp-sidebar>
  )
  // Html Version
  const closeMenu = () => {
    const menubar = document.getElementById('menubar')
    menubar.classList.remove('open')
    const sidebarmask = document.getElementById('sidebarmask')
    sidebarmask.classList.remove('open')
  }

  return (
    <div id="menubar">
      <div role="button" aria-label="close sidebar" onClick={closeMenu} tabIndex="0" className="close-sidebar">✕</div>
      <ul className="sidebar">
        {navigation[lang].map((navItem, i) => (
          <li><a href={navItem.url}>{navItem.label}</a></li>
        ))}
      </ul>
    </div>

  )
}

Navigation.defaultProps = {
  navClass: `site-nav-item`,
  lang: 'en'
}

Navigation.propTypes = {
  isAmp: PropTypes.bool,
  lang: PropTypes.string,
  navClass: PropTypes.string,
}

export default Navigation
