import React from 'react'
import PropTypes from 'prop-types'

const resources = {
  en: {
    title: 'Philly COVID-19 FAQ',
    path: '/'
  },
  es: {
    title: 'FAQ sobre la COVID-19 en Filadelfia',
    path: '/es'
  }
}

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
const Header = ({ data, isAmp, lang = 'en' }) => {
  const openMenu = () => {
    const menubar = document.getElementById('menubar')
    menubar.classList.add('open')
    const sidebarmask = document.getElementById('sidebarmask')
    sidebarmask.classList.add('open')
  }

  const LangLink = ({ lang }) => {
    return lang === 'es' ? (
      <a href="/">English</a>
    ) : (
      <a href="/es/">Español</a>
    )
  }

  return (
    <header>
      <div className="logo">
        <a href="https://resolvephilly.org/" title="Resolve Philly" rel="home">
          <img src="/images/resolve-logo-200.png" height="40" width="100" alt="Resolve Philly" />
        </a>
      </div>
      <div className="label">
        <a href={resources[lang].path}>{resources[lang].title}</a>
      </div>
      <div className="langLink">
        <LangLink lang={lang} />
      </div>
      {isAmp ? (
        <div role="button" on="tap:menubar.toggle" tabIndex="0" className="hamburger">☰</div>
      ) : (
        <>
          <div role="button" onClick={openMenu} tabIndex="0" className="hamburger">☰</div>
          <div id="sidebarmask" />
        </>
      )}
    </header>
  )
}

Header.propTypes = {
  isAmp: PropTypes.bool
}

export default Header
