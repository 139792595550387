// Copyright 2018, Distributed Media Lab, Inc.

module.exports = {
  siteUrl: `https://resolvephilly.ampd.news`, // Site domain. Do not include a trailing slash!

  postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

  siteTitleMeta: `Resolve Philly`, // This allows an alternative site title for meta data for pages.
  siteDescriptionMeta: `Resolve Philly's Covid-19 FAQ`, // This allows an alternative site description for meta data for pages.

  shareImageWidth: 1000, // Change to the width of your default share image
  shareImageHeight: 523, // Change to the height of your default share image

  shortTitle: `ResolvePhilly`, // Used for App manifest e.g. Mobile Home Screen
  siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
  backgroundColor: `#e9e9e9`, // Used for Offline Manifest
  themeColor: `#15171A`, // Used for Offline Manifest

  airtableApiKey: 'keyFQzTb6LXNfaklv',

  // Mapping for partner logos
  // Src is assumed to match tag name unless specified otherwise
  partners: {
    aldia: {
      domain: 'aldianews.com',
      logo: { width: 441, height: 60 }
    },
    billypenn: {
      domain: 'billypenn.com',
      logo: { width: 225, height: 60 }
    },
    philadelphiacitizen: {
      domain: 'thephiladelphiacitizen.org',
      logo: { width: 235, height: 60 }
    },
    cityandstate: {
      domain: 'cityandstateny.com',
      logo: { width: 181, height: 60 }
    }, // ugly
    cityofphiladelphia: {
      domain: 'phila.gov',
      logo: { width: 228, height: 60 }
    },
    ecowurd: {
      domain: 'ecowurd.com',
      logo: { width: 350, height: 60 }
    },
    funtimes: {
      domain: 'funtimesmagazine.com',
      logo: { width: 257, height: 60 }
    },
    generocity: {
      domain: 'generocity.org',
      logo: { width: 305, height: 60 }
    },
    kensingtonvoice: {
      domain: 'kensingtonvoice.com',
      logo: { width: 305, height: 60 }
    },
    muhlenberg: {
      domain: 'muhlenberg.edu',
      logo: { width: 307, height: 60 }
    },
    nbc10philadelphia: {
      domain: 'nbc10.com',
      logo: { width: 260, height: 60 }
    },
    newsintegrityinitiative: {
      domain: 'journalism.cuny.edu',
      logo: { width: 210, height: 60 }
    },
    philadelphiainquirer: {
      domain: 'inquirer.com',
      logo: { width: 441, height: 60 }
    },
    philadelphiamagazine: {
      domain: 'phillymag.com',
      logo: { width: 200, height: 60 }
    },
    philadelphiatribune: {
      domain: 'phillytrib.com',
      logo: { width: 312, height: 60 }
    },
    phillycam: {
      domain: 'phillycam.org',
      logo: { width: 274, height: 60 }
    },
    notebook: {
      domain: 'thenotebook.org',
      logo: { width: 312, height: 60 }
    },
    philadelphiafoundation: {
      domain: 'philafound.org',
      logo: { width: 142, height: 60 }
    },
    technically: {
      domain: 'technical.ly',
      logo: { width: 177, height: 60 }
    },
    whyy: {
      domain: 'whyy.org',
      logo: { width: 204, height: 60 }
    },
    wurd: {
      domain: 'wurdradio.com',
      logo: { width: 187, height: 60 }
    }
  },
  // partnerLogos: {
  //   aldia: { width: 441, height: 60 },
  //   billypenn: { width: 225, height: 60 },
  //   philadelphiacitizen: { width: 235, height: 60 },
  //   cityandstate: { width: 181, height: 60 }, // ugly
  //   ecowurd: { width: 350, height: 60 },
  //   funtimes: { width: 257, height: 60 },
  //   generocity: { width: 305, height: 60 },
  //   kensingtonvoice: { width: 305, height: 60 },
  //   muhlenberg: { width: 307, height: 60 },
  //   newsintegrityinitiative: { width: 210, height: 60 },
  //   philadelphiainquirer: { width: 441, height: 60 },
  //   philadelphiamagazine: { width: 200, height: 60 },
  //   philadelphiatribune: { width: 312, height: 60 },
  //   phillycam: { width: 274, height: 60 },
  //   notebook: { width: 312, height: 60 },
  //   philadelphiafoundation: { width: 142, height: 60 },
  //   technically: { width: 177, height: 60 },
  //   whyy: { width: 204, height: 60 },
  //   wurd: { width: 187, height: 60 }
  // },

  frontpageTags: {
    en: ["childcare", "unemployment", "workers-rights", "latest-health-recommendations"],
    es: ["desempleo", "derechos-laborales", "cuidado-infantil", "ultimas-recomendaciones-de-salud"]
  },

  // Navigation, with en and es versions
  navigation: {
    en: [
      { label: 'Home', url: 'https://resolvephilly.ampd.news/' },
      { label: 'Unemployment', url: 'https://resolvephilly.ampd.news/unemployment/' },
      { label: 'Worker\'s Rights', url: 'https://resolvephilly.ampd.news/workers-rights/' },
      { label: 'Childcare', url: 'https://resolvephilly.ampd.news/childcare/' },
      { label: 'Health Recommendations', url: 'https://resolvephilly.ampd.news/latest-health-recommendations/' },
      { label: 'Resource Map', url: 'https://resolvephilly.ampd.news/resourcesmap/' },
      { label: 'Versión en Español', url: 'https://resolvephilly.ampd.news/es/' },
      { label: 'About Resolve Philly', url: 'https://resolvephilly.org/' }
    ],
    es: [
      { label: 'Página principal', url: 'https://resolvephilly.ampd.news/' },
      { label: 'Desempleo', url: 'https://resolvephilly.ampd.news/desempleo/' },
      { label: 'Derechos Laborales', url: 'https://resolvephilly.ampd.news/derechos-laborales/' },
      { label: 'Cuidado Infantil', url: 'https://resolvephilly.ampd.news/cuidado-infantil/' },
      { label: 'Recomendaciones de salud', url: 'https://resolvephilly.ampd.news/ultimas-recomendaciones-de-salud/' },
      { label: 'English Version', url: 'https://resolvephilly.ampd.news/' },
      { label: 'Sobre Resolve Philly', url: 'https://resolvephilly.org/' }
    ]
  },
  labels: {
    en: {
      relatedContent: 'Related Content'
    },
    es: {
      relatedContent: 'Contenido relacionado'
    }
  }
}
